const {
  REACT_APP_API_URL: APP_URL,
  NODE_ENV,
  RBN_VERSION,
  REACT_APP_GA_ID: GA_ID,
  REACT_APP_SENTRY_ENV: SENTRY_ENV,
  REACT_APP_HEAP_ID: HEAP_ID,
} = process.env

export const CLOUDINARY_UPLOAD_PRESET = 'rc7moltq' // 'bmzjbxoq' // dfbb6rcun
export const CLOUDINARY_UPLOAD_URL = 'https://api.cloudinary.com/v1_1/dfbb6rcun/image/upload' // 'https://api.cloudinary.com/v1_1/react-cloudinary/upload'
export const IS_PRODUCTION = NODE_ENV === 'production'
export const IS_TEST_MODE = !!window.Cypress

export const superSupportAccessEmails = [
  'craig@reos.co.za',
  'duane@reos.co.za',
  'rory@rentalconnect.co.za',
  'marco@reos.co.za',
  'simone@reos.co.za',
  'ernst@reos.co.za',
  'anri@reos.co.za',
  'janine@reos.co.za',
  'dani@reos.co.za',
]

export { APP_URL, NODE_ENV, RBN_VERSION, GA_ID, SENTRY_ENV, HEAP_ID }
